/* @flow */

import * as types from './uiOnboardingActionTypes';

export function markSplashAsSeen(): Object {
  return {
    type: types.MARK_SPLASH_AS_SEEN,
  };
}

export function completeOnboardingOnDevice(): Object {
  return {
    type: types.COMPLETE_ONBOARDING_ON_DEVICE,
  };
}

export function resetOnboardingOnDevice(): Object {
  return {
    type: types.RESET_ONBOARDING_ON_DEVICE,
  };
}

export function markTimezoneWarningAsShown(): Object {
  return {
    type: types.MARK_TIMEZONE_WARNING_AS_SHOWN,
  };
}
