/* @flow */

export default {
  root: {
    width: ({ imgWidth }: Object) => imgWidth,
    height: 150,

    objectFit: 'contain',
  },
};
