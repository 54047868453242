/* @flow */

import { rem } from 'polished';

import { tablet } from '@braindate/ui/lib/token/mediaQuery';
import { baseFontSize } from '@braindate/ui/lib/token/typography';

import { borderRadius } from 'src/shared/app/base/style/token/box';
import { boxShadow } from 'src/shared/app/base/style/token/elevation';
import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  root: {
    position: 'relative',

    display: 'flex',
    flexDirection: 'column',

    width: '100%',
    height: '100vh',

    [tablet]: {
      flexDirection: 'row',
    },
  },
  top: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    height: '50%',

    [tablet]: {
      width: '50%',
      height: '100%',
    },
  },
  bottom: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    height: '50%',

    backgroundColor: ({ theme }: ThemeFunction<{}>) => theme.colorPrimary,

    [tablet]: {
      width: '50%',
      height: '100%',
    },
  },
  logo: {
    maxWidth: '313px !important',
  },
  helpCtn: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },
  help: {
    maxWidth: 400,
    margin: [rem(16, baseFontSize), 'auto'],
    padding: rem(8, baseFontSize),

    backgroundColor: ({ theme }: ThemeFunction<{}>) => theme.colorPrimary,
    borderRadius,
    boxShadow,
    color: ({ theme }: ThemeFunction<{}>) => theme.textColor,

    textAlign: 'center',
  },
};
